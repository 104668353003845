exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-join-js": () => import("./../../../src/templates/join.js" /* webpackChunkName: "component---src-templates-join-js" */),
  "component---src-templates-kiosk-js": () => import("./../../../src/templates/kiosk.js" /* webpackChunkName: "component---src-templates-kiosk-js" */),
  "component---src-templates-ressources-js": () => import("./../../../src/templates/ressources.js" /* webpackChunkName: "component---src-templates-ressources-js" */),
  "component---src-templates-service-unit-js": () => import("./../../../src/templates/service-unit.js" /* webpackChunkName: "component---src-templates-service-unit-js" */),
  "component---src-templates-services-digitalspace-js": () => import("./../../../src/templates/services/digitalspace.js" /* webpackChunkName: "component---src-templates-services-digitalspace-js" */),
  "component---src-templates-services-index-js": () => import("./../../../src/templates/services/index.js" /* webpackChunkName: "component---src-templates-services-index-js" */),
  "component---src-templates-solution-unit-js": () => import("./../../../src/templates/solution-unit.js" /* webpackChunkName: "component---src-templates-solution-unit-js" */),
  "component---src-templates-solutions-js": () => import("./../../../src/templates/solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */),
  "component---src-templates-template-article-js": () => import("./../../../src/templates/template-article.js" /* webpackChunkName: "component---src-templates-template-article-js" */),
  "component---src-templates-template-base-js": () => import("./../../../src/templates/template-base.js" /* webpackChunkName: "component---src-templates-template-base-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

